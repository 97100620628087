var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"vote"}},[_c('div',{staticClass:"arrows"},[_c('span',{staticClass:"nominee",style:(_vm.nomineeStyle)}),_c('span',{staticClass:"nominator",style:(_vm.nominatorStyle)})]),_c('div',{staticClass:"overlay"},[_c('audio',{attrs:{"src":require("../assets/sounds/countdown.mp3"),"preload":"auto"}}),_c('em',{staticClass:"blue"},[_vm._v(_vm._s(_vm.nominator.name))]),_vm._v(" nominated "),_c('em',[_vm._v(_vm._s(_vm.nominee.name))]),_vm._v("! "),_c('br'),(!_vm.session.isSpectator || _vm.session.isVoteWatchingAllowed)?[_c('em',{staticClass:"blue"},[_vm._v(" "+_vm._s(_vm.voteCount)+" vote"+_vm._s(_vm.voteCount !== 1 ? "s" : "")+" ")]),_vm._v(" in favor ")]:_vm._e(),(_vm.nominee.role.team !== 'traveler')?_c('em',[_vm._v(" (majority is "+_vm._s(Math.ceil(_vm.alive / 2))+") ")]):_c('em',[_vm._v("(majority is "+_vm._s(Math.ceil(_vm.players.length / 2))+")")]),(!_vm.session.isSpectator)?[(
          _vm.session.isVoteWatchingAllowed &&
          !_vm.session.isVoteInProgress &&
          _vm.session.lockedVote < 1
        )?_c('div',[_vm._v(" Time per player: "),_c('font-awesome-icon',{attrs:{"icon":"minus-circle"},on:{"mousedown":function($event){$event.preventDefault();return _vm.setVotingSpeed(-500)}}}),_vm._v(" "+_vm._s(_vm.session.votingSpeed / 1000)+"s "),_c('font-awesome-icon',{attrs:{"icon":"plus-circle"},on:{"mousedown":function($event){$event.preventDefault();return _vm.setVotingSpeed(500)}}})],1):_vm._e(),_c('div',{staticClass:"button-group"},[(!_vm.session.isVoteInProgress)?_c('div',{staticClass:"button townsfolk",on:{"click":_vm.countdown}},[_vm._v(" Countdown ")]):_vm._e(),(!_vm.session.isVoteInProgress)?_c('div',{staticClass:"button",on:{"click":_vm.start}},[_vm._v(" "+_vm._s(_vm.session.lockedVote ? "Restart" : "Start")+" ")]):[_c('div',{staticClass:"button townsfolk",class:{ disabled: !_vm.session.lockedVote },on:{"click":_vm.pause}},[_vm._v(" "+_vm._s(_vm.voteTimer ? "Pause" : "Resume")+" ")]),_c('div',{staticClass:"button",on:{"click":_vm.stop}},[_vm._v("Reset")])],_c('div',{staticClass:"button demon",on:{"click":_vm.finish}},[_vm._v("Close")])],2),(_vm.nominee.role.team !== 'traveler')?_c('div',{staticClass:"button-group mark"},[_c('div',{staticClass:"button",class:{
            disabled: _vm.session.nomination[1] === _vm.session.markedPlayer,
          },on:{"click":_vm.setMarked}},[_vm._v(" Mark for execution ")]),_c('div',{staticClass:"button",on:{"click":_vm.removeMarked}},[_vm._v("Clear mark")])]):_vm._e()]:(_vm.canVote)?[(_vm.session.isVoteWatchingAllowed && !_vm.session.isVoteInProgress)?_c('div',[_vm._v(" "+_vm._s(_vm.session.votingSpeed / 1000)+" seconds between votes ")]):_vm._e(),_c('div',{staticClass:"button-group"},[_c('div',{staticClass:"button townsfolk",class:{ disabled: !_vm.currentVote },on:{"click":function($event){return _vm.vote(0)}}},[_vm._v(" Hand DOWN ")]),_c('div',{staticClass:"button demon",class:{ disabled: _vm.currentVote === 1 },on:{"click":function($event){return _vm.vote(1)}}},[_vm._v(" Hand UP ")]),(_vm.player.hasTwoVotes)?_c('div',{staticClass:"button demon",class:{ disabled: _vm.currentVote === 2 },on:{"click":function($event){return _vm.vote(2)}}},[_vm._v(" x2 ")]):_vm._e()])]:(!_vm.player)?_c('div',[_vm._v("Please claim a seat to vote.")]):_vm._e()],2),_c('transition',{attrs:{"name":"blur"}},[(_vm.session.isVoteInProgress && !_vm.session.lockedVote)?_c('div',{staticClass:"countdown"},[_c('span',[_vm._v("3")]),_c('span',[_vm._v("2")]),_c('span',[_vm._v("1")]),_c('span',[_vm._v("GO")]),_c('audio',{attrs:{"autoplay":!_vm.grimoire.isMuted,"src":require("../assets/sounds/countdown.mp3"),"muted":_vm.grimoire.isMuted}})]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }